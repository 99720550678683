import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../services/error-handler.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ReapplyService {

  defaultProgramSpec = '';

  constructor(
    // tslint:disable-next-line:variable-name
    private _authHttp: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  public get authHttp(): HttpClient {
    return this._authHttp;
  }
  public set authHttp(value: HttpClient) {
    this._authHttp = value;
  }

  /* istanbul ignore next */
  public cancelReconWhenReapply(userId: string, passId: string, selectedProgramCode: string): Observable<any> {

    const requestURI = environment.uriEndpoint +
                      'v1/goesapp/users/' + userId +
                      '/reconsiderations/cancel/' + passId +
                      '?programCode=' + selectedProgramCode;

    return this.authHttp.get(requestURI).pipe(
      map(() => {
        return true;
      }),
      catchError(error => this.errorHandler.handleError(error)));
   }

  getDefaultProgramSpec(): string {
    return this.defaultProgramSpec;
  }

  setDefaultProgramSpec(value: string): void {
    this.defaultProgramSpec = value;
  }

}
